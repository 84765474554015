import * as moment from 'moment';
import 'fullcalendar';

import UrlParams from './url_params';

export default class Events {
  constructor() {
    const urlParams = new UrlParams;
    this.parseQueryString = urlParams.parseQueryString;
    this.updateQueryStringParam = urlParams.updateQueryStringParam;

    this.calendar = $('#calendar');
    this.singleDay = $('#single-day');
    this.listView = $('#list-view');
    this.eventResults = $('#event-results');

    this.primaryCategory = '';
    this.secondaryCategory = '';
    this.eventQuery = '';
    this.defaultView = 'month';

    this.selectedDate;

    this.initView();
    this.initFilters();
    this.initCalendar();

    this.viewToggle = $('.event-views__button');

    this.viewToggle.on('click', (e) => {
      const target = $(e.currentTarget || e.target);
      this.viewChange(target);
    });

    $('#primary-categories').on('change', (e) => {
      const selectedCategory = $(e.currentTarget || e.target).val();
      this.showLoadingSpinners();
      this.loadSecondaryCategories(selectedCategory, true);

      const primaryValue = $('#primary-categories').val();
      this.primaryCategory = primaryValue ? primaryValue : '';

      if (this.eventQuery) {
        this.showResults();
      }
    });

    $('.secondary-categories select').on('change', () => {
      this.showLoadingSpinners();

      const secondaryValue = $('.secondary-categories select').val();
      this.secondaryCategory = secondaryValue ? secondaryValue : '';

      if (this.eventQuery) {
        this.showResults();
      } else {
        this.reloadEvents();
      }
    });

    $('#event-query').on('change', (e) => {
      this.eventQuery = $(e.currentTarget || e.target).val();
    });

    $('#event-search').on('submit', (e) => {
      e.preventDefault();

      this.showResults();
      this.showLoadingSpinners();
    });
  }

  initView() {
    const query = window.location.search.substring(1);
    const qs = this.parseQueryString(query);

    if (qs.defaultView == 'list') {
      this.defaultView = qs.defaultView;
      $(".event-views__button[data-view='month']").removeClass('active');
      $(".event-views__button[data-view='list']").addClass('active');
      $('.events-calendar').addClass('list-view');
    }

    $('.events-calendar').removeClass('initialize');
  }

  initFilters() {
    $('#event-query').val('');
    const query = window.location.search.substring(1);
    const qs = this.parseQueryString(query);

    let preselectedFilter = '';
    if (qs.primary) {
      preselectedFilter = qs.primary;
    } else {
      preselectedFilter = $('#primary-categories').val();
    }

    if (preselectedFilter) {
      this.primaryCategory = preselectedFilter;

      if (qs.primary) {
        $('#primary-categories').val(qs.primary);
        this.loadSecondaryCategories(qs.primary);
      }

      if (qs.secondary) {
        this.secondaryCategory = qs.secondary;
      }

      $('.secondary-categories select').html('');

      $.ajax({
        url: '/events/secondary_categories.json',
        data: {
          primary_id: preselectedFilter
        }
      }).done((data) => {
        if (data.length) {
          let secondaryOptions = '<option value="">All Types</option>';

          data.forEach(datum => {
            const newOption = '<option value="' + datum.slug + '">' + datum.name + '</option>';
            secondaryOptions += newOption
          });

          $('.secondary-categories select').html(secondaryOptions);
          $('.secondary-categories').css('display', 'block');

          if (qs.secondary) {
            $('.secondary-categories select').val(qs.secondary);
          }
        } else {
          $('.secondary-categories').css('display', 'none');
          $('.secondary-categories select').html('');
        }
      });
    }
  }

  initCalendar() {
    this.calendar.fullCalendar({
      defaultView: this.defaultView,
      events: '/events.json?primary=' + this.primaryCategory + '&secondary=' + this.secondaryCategory + '&query=',
      header: {
        left: '',
        center: 'prev,title,next',
        right: ''
      },
      views: {
        list: {
          duration: {
            months: 1
          }
        },
        results: {
          type: 'list',
          duration: {
            days: 0
          }
        }
      },
      dayNamesShort: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
      height: 'auto',
      fixedWeekCount: false,
      showNonCurrentDates: false,
      eventAfterRender: (e, element) => {
        if (element.parent().parent().index() === 0) {
          const index = element.parent().index();
          element.closest('.fc-week').find('.fc-content-skeleton thead td').eq(index).addClass('fc-has-event');
        }

        $('.fc-icon-left-single-arrow').attr('aria-label', 'previous month');
        $('.fc-prev-button').attr('aria-label', 'previous month');
        $('.fc-icon-right-single-arrow').attr('aria-label', 'next month');
        $('.fc-next-button').attr('aria-label', 'next month');
      },
      eventAfterAllRender: (view) => {
        const sources = this.calendar.fullCalendar('getEventSources');

        $('.fc-state-highlight').children('.fc-day-number').addClass('active');

        if (!this.selectedDate) {
          $('.fc-today').find('.fc-day-number').addClass('active');
        } else {
          const dateString = moment(this.selectedDate).format('YYYY-MM-DD');
          $('.fc-day-top[data-date="' + dateString + '"]').find('.fc-day-number').addClass('active');
        }

        if (!this.selectedDate) {
          this.selectedDate = this.calendar.fullCalendar('getDate');
          this.singleDay.next().fadeIn(100);
        }

        $('.fc-day-number').on('click', (e) => {
          this.calendar.find('.fc-day-number').removeClass('active');
          $(e.currentTarget || e.target).addClass('active');
        });

        if (sources.length > 0) {
          if (view.type === 'month') {
            $.ajax({
              url: '/events/day_events.js?primary=' + this.primaryCategory + '&secondary=' + this.secondaryCategory,
              data: {
                day: this.selectedDate.format()
              },
              success: () => {
                this.singleDay.next().fadeOut(300);
              }
            });
          } else {
            this.listView.next().fadeIn(100);

            $.ajax({
              url: '/events.js?primary=' + this.primaryCategory + '&secondary=' + this.secondaryCategory,
              data: {
                start_date: view.intervalStart.format(),
                end_date: view.intervalEnd.format()
              },
              success: () => {
                this.listView.next().fadeOut(300);
              }
            });
          }
        }

        this.calendar.next().fadeOut(300);
      },
      dayClick: (date) => {
        this.selectedDate = date;
        this.singleDay.next().fadeIn(100);

        $.ajax({
          url: '/events/day_events.js?primary=' + this.primaryCategory + '&secondary=' + this.secondaryCategory,
          data: {
            day: date.format()
          },
          success: () => {
            this.singleDay.next().fadeOut(300);
          }
        });
      }
    });

    $('.fc-prev-button, .fc-next-button').on('click', () => {
      if ($('.events-calendar').hasClass('list-view')) {
        this.listView.next().fadeIn(100);
      } else {
        this.calendar.next().fadeIn(100);
      }
    });
  }

  viewChange(target) {
    $('#event-query').val('');

    if (!target.hasClass('active')) {
      this.viewToggle.removeClass('active');
      target.addClass('active');

      const targetView = target.data('view');

      $('.events-calendar').removeClass('event-results');

      if (targetView === 'list') {
        $('.events-calendar').addClass('list-view');
      } else {
        $('.events-calendar').removeClass('list-view');
      }

      this.showLoadingSpinners();

      this.selectedDate = this.calendar.fullCalendar('getDate');
      this.calendar.fullCalendar('changeView', targetView);
    }
  }

  showResults() {
    $('.events-calendar').removeClass('list-view');

    if (this.eventQuery) {
      $('.events-calendar').addClass('event-results');
      this.viewToggle.removeClass('active');
      this.calendar.fullCalendar('changeView', 'results');
    } else {
      this.viewChange($(".event-views__button[data-view='month']"));
    }

    $.ajax({
      url: '/events.js?primary=' + this.primaryCategory + '&secondary=' + this.secondaryCategory + '&query=' + this.eventQuery,
        data: {
          start_date: moment().format(),
          end_date: moment().add(1, 'year').format()
        },
        success: () => {
          this.eventResults.next().fadeOut(300);
        }
    })
  }

  loadSecondaryCategories(selectedCategory, reload) {
    this.updateQueryStringParam('secondary', '');

    if (selectedCategory) {
      this.secondaryCategory = '';

      $.ajax({
        url: '/events/secondary_categories.json',
        data: {
          primary_id: selectedCategory
        }
      }).done((data) => {
        if (data.length) {
          let secondaryOptions = '<option value="">All Types</option>';

          data.forEach(datum => {
            const newOption = '<option value="' + datum.slug + '">' + datum.name + '</option>';
            secondaryOptions += newOption
          });

          $('.secondary-categories select').html(secondaryOptions);
          $('.secondary-categories').css('display', 'block');
        } else {
          $('.secondary-categories').css('display', 'none');
          $('.secondary-categories select').html('');
        }

        if (reload) {
          this.reloadEvents();
        }
      });
    } else {
      this.primaryCategory = '';
      this.secondaryCategory = '';

      $('.secondary-categories').css('display', 'none');
      $('.secondary-categories select').html('');

      if (reload && !this.eventQuery) {
        this.reloadEvents();
      }
    }
  }

  reloadEvents() {
    this.calendar.next().fadeIn(100);

    this.updateQueryStringParam('primary', this.primaryCategory);
    this.updateQueryStringParam('secondary', this.secondaryCategory);

    this.calendar.find('.fc-day-number').removeClass('active');
    this.calendar.find('.fc-day-top').removeClass('fc-has-event');

    this.calendar.fullCalendar('removeEventSources');
    this.calendar.fullCalendar('addEventSource', '/events.json?primary=' + this.primaryCategory + '&secondary=' + this.secondaryCategory);
  }

  showLoadingSpinners() {
    if ($('.events-calendar').hasClass('list-view')) {
      this.listView.next().fadeIn(100);
    } else if ($('.events-calendar').hasClass('event-results')) {
      this.eventResults.next().fadeIn(100);
    } else {
      this.singleDay.next().fadeIn(100);
    }
  }
}