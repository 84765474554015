export default class Search {
  constructor() {
    $('#search, .search-overlay__close').on('click', () => {
      this.toggleSearch();
      $('.search-overlay form input[type=search]').focus().val($('.search-overlay form input[type=search]').val());
    });

    $('#load-more-results').on('click', (e) => {
      $(e.currentTarget || e.target).css('display', 'none');
    });
  }

  toggleSearch() {
    $('.search-overlay').fadeToggle(400);
  }
}
