export default class Wysiwyg {
  constructor() {
    let wysiwygLinks = [...$('.inline-link')];

    wysiwygLinks.forEach((e) => {
      let current = $(e).html()
      $(e).html(current + '<span></span>');
    })

    let pageContent = [...$('main').children()];
    pageContent.forEach((e) => {
      if ($(e).next().length) {
        if ($(e).attr('class') && $(e).next().attr('class')) {
          if ($(e).attr('class').includes('gallery-wrap') && $(e).next().attr('class').includes('gallery-wrap') || $(e).next().attr('class').includes('formstack-form')) {
            $(e).addClass('shortener');
          }
        }
      }
    });
  }
}