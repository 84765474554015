export default class Curlies {
  constructor() {
    let everything = [];
    everything.push(...$('p'));
    everything.push(...$('h1'));
    everything.push(...$('h3'));
    everything.push(...$('h3'));
    everything.push(...$('h4'));
    everything.push(...$('h5'));
    everything.push(...$('h6'));
    everything.push(...$('a'));

    everything.forEach((e) => {
      if ($(e).contents().length > 0) {
        $(e).contents().each((i, x) => {
          if (x.nodeType == Node.TEXT_NODE) {
            $(e).contents()[i].nodeValue = this.smarten($(x).text().toString());
          }
        });
      }
    });
  }

  smarten(text) {
    return text
        /* opening singles */
        .replace(/(^|[-\u2014\s(\["])'/g, "$1\u2018")

        /* closing singles & apostrophes */
        .replace(/'/g, "\u2019")

        /* opening doubles */
        .replace(/(^|[-\u2014/\[(\u2018\s])"/g, "$1\u201c")

        /* closing doubles */
        .replace(/"/g, "\u201d")

        /* em-dashes */
        .replace(/--/g, "\u2014");
  }
}
