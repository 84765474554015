export default class Breadcrumbs {
  constructor() {
    this.breadcrumbButton = $('.current button');

    this.breadcrumbButton.on('click', () => {
      this.toggleDropdown();
    });

    $('body').on('click', (e) => {
      if (!$(e.target).closest('#bc-drop').length && !$(e.target).closest('li.current').length) {
        $('.breadcrumb-dropdown').removeClass('active');
      }
    });
  }

  toggleDropdown() {
    $('.breadcrumb-dropdown').toggleClass('active');
  }
}