import Cookies from 'js-cookie';

export default class BannerAlert {
  constructor() {
    this.banner = $('.banner-container');
    this.bannerCookie = Cookies.get('cen-banner-dismiss');
    this.close = $('.banner__close, .button--no-thanks');
    this.sessionData = this.banner.data('session');
    this.link = this.banner.find('.button--secondary--filled');

    this.close.on('click', () => {
      this.closeAlert();
    });


    if (this.bannerCookie && (this.bannerCookie !== this.sessionData)) {
      this.resetCookie();
    } else if (!this.bannerCookie) {
      this.showBanner();
    }

    this.link.on('click', (e) => {
      this.redirect();
    });
  }

  closeAlert() {
    Cookies.set('cen-banner-dismiss', this.sessionData, { expires: 1 });
    this.banner.fadeOut(300);
  }

  resetCookie() {
    Cookies.remove('cen-banner-dismiss');
    this.banner.fadeIn(300);
  }

  showBanner() {
    this.banner.fadeIn(300);
  }

  redirect() {
    Cookies.set('cen-banner-dismiss', this.sessionData, { expires: 1 });
  }
}