export default class Faculty {
  constructor() {
    this.facultyForm = $('#faculty-search');
    this.administrationForm = $('#administration-search');

    this.initFacultySearch();
  }

  initFacultySearch() {
    this.facultyForm.on('submit', (e) => {
      e.preventDefault();
      this.submitFacultyForm();
    });

    this.administrationForm.on('submit', (e) => {
      e.preventDefault();
      this.submitAdministrationForm();
    });

    this.facultyForm.find('select').on('change', () => {
      this.submitFacultyForm();
    });

    this.administrationForm.find('select').on('change', () => {
      this.submitAdministrationForm();
    });

    $('.faculty-search__load-more a').on('click', (e) => {
      $(e.currentTarget || e.target).css('display', 'none');
    });
  }

  submitFacultyForm() {
    const departmentValue = this.facultyForm.find('select').val();
    const searchValue = this.facultyForm.find('input').val();

    $.ajax({
      url: '/faculty_members.js',
      data: {
        department: departmentValue,
        query: searchValue
      }
    });
  }

  submitAdministrationForm() {
    const departmentValue = this.administrationForm.find('select').val();
    const searchValue = this.administrationForm.find('input').val();

    $.ajax({
      url: '/faculty_members/administration.js',
      data: {
        department: departmentValue,
        query: searchValue
      }
    });
  }
}