import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'slick-carousel';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import objectFitImages from 'object-fit-images';

import Accordion from './accordion';
import Banner from './banner';
import Breadcrumbs from './breadcrumbs';
import Curlies from './curlies';
import Events from './events';
import Faculty from './faculty';
import Menu from './menu';
import Search from './search';
import SectionNavigation from './section-navigation';
import Slick from './slick';
import Video from './video';
import Wysiwyg from './wysiwyg';

new Accordion();
new Banner();
new Breadcrumbs();
new Curlies();
new Events();
new Faculty();
new Menu();
new SectionNavigation();
new Search();
new Slick();
new Video();
new Wysiwyg();

objectFitImages();
