export default class Video {
  constructor() {
    // Inject YouTube API script
    var tag = document.createElement('script');
    tag.src = "//www.youtube.com/player_api";
    var firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

    var player;

    function onYouTubePlayerAPIReady() {
      // create the global player from the specific iframe (#video)
      player = new YT.Player('video', {
        events: {
          // call this function when player is ready to use
          'onReady': onPlayerReady
        }
      });
    }

    function onPlayerReady(event) {
      // bind events
      var playButton = document.getElementById("media__play");
      playButton.addEventListener("click", function() {
        player.playVideo();
      });
    }

    $('.media__cover').on('click', (e) => {
      const target = $(e.currentTarget);
      this.playVideo(target);
    });

    $('.media__cover__modal').on('click', (e) => {
      const target = $(e.currentTarget);
      this.openModal(target);
    });

    $('.video__gallery__close').on('click', (e) => {
      $('.video-modal').removeClass('open');
      if ($(e.currentTarget).siblings($('iframe')).length > 0) {
        $(e.currentTarget).siblings($('iframe')).attr("src", $('iframe').attr("src").replace("autoplay=1", "autoplay=0"));
      }
      if ($('video').length > 0) {
        $('video').pause();
      }
    });
  }

  openModal(target) {
    target.siblings('.video-only').addClass('open');
    const video = target.siblings('.video-only').children('video');
    const iframe = target.siblings('.video-only').children('iframe');

    if (iframe.length) {
      $(iframe[0]).attr("src", $(iframe[0]).attr("src").replace("autoplay=0", "autoplay=1"));
    } else {
      video[0].play();
    }
  }

  playVideo(target) {
    const video = target.siblings('video');
    const iframe = target.siblings('iframe');

    target.fadeOut(600);

    if (iframe.length) {
      $(iframe[0]).attr("src", $(iframe[0]).attr("src").replace("autoplay=0", "autoplay=1"));
    } else {
      video[0].play();
    }
  }
}
