export default class Slick {
  constructor() {
    this.slickify();

    $(window).on('resize', () => {
      this.slickify();
      this.slickImageGrid();
    });

    this.initGalleries();

    this.slickImageGrid();
  }

  slickImageGrid() {
    if ($(window).width() <= 800) {
      $('.image-grid').slick({
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        variableWidth: false,
        draggable: true,
        adaptiveHeight: true
      });
    } else if ($('.image-grid').hasClass('slick-initialized')) {
      $('.image-grid').slick('unslick');
    }
  }

  slickify() {
    var windowWidth = $(window).width();
    var slick = $('.slick');

    if (windowWidth > 800 && slick.hasClass('slick-slider')) {
      slick.slick('unslick');
    } else if (windowWidth < 800 && !slick.hasClass('slick-slider')) {
      slick.slick({
        dots: false,
        infinite: true,
        slidesToShow: 2,
        slidesToScroll: 2,
        responsive: [
          {
            breakpoint: 501,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            }
          },
        ]
      });
    }
  }

  initGalleries() {
    $(document).on('beforeChange', '.gallery__main', (e, slick, currentSlide, nextSlide) => {
      const nav = slick.$slider.next();

      if (nav.hasClass('gallery__nav')) {
        const thumbs = nav.find('.thumb');

        thumbs.removeClass('active');
        thumbs.eq(nextSlide).addClass('active');
      }
    });

    $(document).on('click', '.gallery__nav .thumb', (e) => {
      const target = $(e.currentTarget || e.target);
      const gallery = target.parents('.gallery').find('.gallery__main');
      const index = target.index();

      target.siblings().removeClass('active');
      target.addClass('active');

      gallery.slick('slickGoTo', index);
    });

    $(document).on('click', '.gallery__close', () => {
      $('.gallery').removeClass('open');
    });

    $(document).on('click', '.gallery-preview', (e) => {
      const target = $(e.currentTarget || e.target);
      const parent = target.parents('.gallery-wrap');

      const gallery = parent.find('.gallery');
      gallery.addClass('open');

      if (!gallery.find('.gallery__main').hasClass('slick-initialized')) {
        $('.gallery__main').slick({
          adaptiveHeight: true
        });
      }
    });
  }
}
