export default class Accordion {
  constructor() {
    let accordions = $('.accordion');

    accordions.each((index, accordion) => {
      let title = $(accordion).find('.accordion__title');
      console.log(title);
      title.on('click', (e) => {
        $(accordion).toggleClass('closed');
        title.next().slideToggle();
      })
    })
  }
}
