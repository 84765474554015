export default class SectionNavigation {
  constructor() {
    this.toggle = $('#toggle-section-nav');

    this.toggle.on('click', (e) => {
      const target = $(e.currentTarget || e.target);
      this.toggleSectionNav(target);
    });
  }

  toggleSectionNav(target) {
    const sectionNav = target.next();

    if (target.hasClass('open')) {
      target.attr('aria-label', 'show section navigation');

      sectionNav
        .stop()
        .slideUp(400);
    } else {
      target.attr('aria-label', 'hide section navigation');

      sectionNav
        .css('display', 'flex')
        .hide()
        .stop()
        .slideDown(400);
    }

    target.toggleClass('open');
  }
}
