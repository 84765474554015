export default class Menu {
  constructor() {
    this.menuToggle = $('.menu-toggle');
    this.mobileMenu = $('.header__nav-wrap');
    this.subnavToggle = $('.subnav-toggle');

    this.menuToggle.on('click', () => {
      this.toggleMobileMenu();
    });

    this.subnavToggle.on('click', (e) => {
      const target = $(e.currentTarget || e.target);
      this.toggleSubnav(target);
    });

    $(window).on('resize', () => {
      if ($(window).width() > 1024) {
        this.mobileMenu.removeClass('header__nav-wrap--open');
        this.menuToggle.removeClass('menu-toggle--open');
        this.menuToggle.removeClass('open');
      }
    });

    $('.mega-menu:not(.mega-menu--subnav) .nav').each((index, parent) => {
      const $parent = $(parent);
      const itemLength = $parent.find('> li').length;

      if (itemLength <= 10) {
        $parent.addClass('two-column');
      }

      if (itemLength > 10 && itemLength <= 12) {
        $parent.addClass('two-column-12');
      }

      if (itemLength > 12 && itemLength <= 14) {
        $parent.addClass('two-column-14');
      }

      if (itemLength > 14 && itemLength <= 16) {
        $parent.addClass('two-column-16');
      }

      if (itemLength > 16 && itemLength <= 18) {
        $parent.addClass('two-column-18');
      }
    });

    $('.mega-sub-menu__nav').each((index, parent) => {
      const $parent = $(parent);
      const itemLength = $parent.find('> li').length;

      if (itemLength <= 20) {
        $parent.addClass('four-column');
      }

      if (itemLength > 20 && itemLength <= 24) {
        $parent.addClass('four-column-24');
      }

      if (itemLength > 24 && itemLength <= 28) {
        $parent.addClass('four-column-28');
      }

      if (itemLength > 28 && itemLength <= 32) {
        $parent.addClass('four-column-32');
      }

      if (itemLength > 32 && itemLength <= 36) {
        $parent.addClass('four-column-36');
      }
    });
  }

  toggleMobileMenu() {
    this.menuToggle.toggleClass('menu-toggle--open');
    this.mobileMenu.toggleClass('header__nav-wrap--open');
  }

  toggleSubnav(target) {
    target.toggleClass('subnav-toggle--open');
    target.next().stop().slideToggle(400);
  }
}